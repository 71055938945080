import type { NextPage } from 'next'
import Head from 'next/head'
import * as React from 'react'
import { HeroContainer, HeroContent, HeroTitle } from '../components'

const Custom404: NextPage = () => (
    <>
        <Head>
            <title>Pagina non trovata | Saturno assicurazioni</title>
        </Head>
        <HeroContainer hasBackground variant="small-one" paddingX={{ mobile: 'none', desktop: 'l' }}>
            <HeroContent width="content">
                <HeroTitle marginBottom="none">Pagina non trovata</HeroTitle>
            </HeroContent>
        </HeroContainer>
    </>
)

export default Custom404
